import { ReqPage } from '@/model/common/reqPage'
// 客户信息类型
export type CustomerMessageType = CustomerMessageClass
// 客户信息
export class CustomerMessageClass {
  id: string | undefined // 用户id 1
  customerName: string | undefined // 客户名称 1
  areaProvince: string | undefined // 所属区域：省 1
  areaCity: string | undefined // 所属区域：市 1
  area: string | undefined // 所属区域
  clientCategories: string | undefined // 客户类别1
  customerSource: string | undefined // 客户来源 1
  developmentState: string | undefined // 发展状态 1
  primaryIndustry: string | undefined // 所属一级行业 1
  secondaryIndustry: string | undefined // 所属二级行业 1
  estimatedProcurementPotential: string | undefined // 采购潜力 1
  salesType: string | undefined // 销售类型 1
  customerType: number | undefined // 客户类型 （0：资料客户；1：潜在客户；2：意向客户；3：成交客户；4：长期客户；5：粉丝客户;undefined:所有客户） 1
  businessManagerName: string | undefined // 业务员名字 1
  businessManagerNo: string | undefined // 业务员工号 1

  businessManagerId: string | undefined // 业务员id 1 2021-5-28
  flowerNo: string | undefined // 跟单人工号 2021-12-20
  customerAbbreviation: string | undefined // 客户简称
  createdBy: string | undefined // 创建人
  createdTime: string | undefined // 创建时间
  updatedBy: string | undefined // 修改人
  updatedTime: string | undefined // 修改时间
  isDelete: number | undefined // 是否删除 0：未删除 1：已删除
  deleteTime: string | undefined // 删除时间
  isEnable: number | undefined // 是否启用 0：禁用  1：启用
  startTime: string | undefined // 开始时间
  endTime: string | undefined // 结束时间
  restrict: number | undefined // 结束时间 是否限制查看客户；为空或0：限制，1：不限制
  // 新增字段
  customerCode: string | undefined // 客户编号" 2021-5-22
  customerDiscountLevel: number | undefined //客户等级

  constructor() {
    this.id = undefined
    this.customerName = undefined
    this.areaProvince = undefined
    this.areaCity = undefined
    this.area = undefined
    this.clientCategories = undefined
    this.customerSource = undefined
    this.developmentState = undefined
    this.primaryIndustry = undefined
    this.secondaryIndustry = undefined
    this.estimatedProcurementPotential = undefined
    this.salesType = undefined
    this.customerType = undefined
    this.businessManagerName = undefined
    this.businessManagerNo = undefined

    this.businessManagerId = undefined
    this.flowerNo = undefined
    this.customerAbbreviation = undefined
    this.createdBy = undefined
    this.createdTime = undefined
    this.updatedBy = undefined
    this.updatedTime = undefined
    this.isDelete = undefined
    this.deleteTime = undefined
    this.isEnable = undefined
    this.startTime = undefined
    this.endTime = undefined
    this.customerCode = undefined
    this.customerDiscountLevel = undefined
    this.restrict = undefined
  }
}
export class ReqCustomerMessageClass extends ReqPage {
  data: CustomerMessageClass
  constructor() {
    super()
    this.data = new CustomerMessageClass()
  }
}
export interface ResCustomerMessage extends ReqPage {
  data: CustomerMessageClass[]
}
