import { $axios } from '../config/axios'
import {
  CustomerMessageClass,
  ReqCustomerMessageClass,
  ResCustomerMessage,
} from '@/model/system/customer'
import Data from '@/model/common/Data'
class CustomerApi {
  // 分页查询
  GetCustomerList(data: ReqCustomerMessageClass): Promise<ResCustomerMessage> {
    return $axios.post('/api/customer/list', data)
  }
  // //根据客户编号查询采购人信息
  GetPurchaserMessage(customerCode: string): Promise<Data[]> {
    return $axios.get('/api/customer/getPurchaserMessage', { params: { customerCode } })
  }
}
// 单列模式返回对象
let customerApi
export default (() => {
  if (customerApi) return customerApi
  customerApi = new CustomerApi()
  return customerApi
})()
